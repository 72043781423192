import { EBD_SUPPLIER } from '../../common/constants'
import { Product } from './types'

const MAX_ALLOWED_QUANTITY_PER_CART = 20000

const getLimitPerOrder = (product: Product) => {
  const { limitPerOrder, supplierPrices, discount } = product

  if (discount) {
    return limitPerOrder == undefined || limitPerOrder == null
      ? MAX_ALLOWED_QUANTITY_PER_CART
      : limitPerOrder
  }

  return !supplierPrices[0].limitPerOrder
    ? MAX_ALLOWED_QUANTITY_PER_CART
    : supplierPrices[0].limitPerOrder
}

export const getProductMaxOrderLimit = (product: Product): number => {
  const [{ getMoreForLess, inStockQuantity, availableQuantity }] = product.supplierPrices

  if (getMoreForLess) {
    const limitInUnits = getMoreForLess.limit * product.packageNumberOfItems!
    const stock = inStockQuantity || 0

    if (product.supplierPrices[0].supplierName == EBD_SUPPLIER) {
      return getMoreForLess.limit
    }

    return Math.min(stock, limitInUnits)
  }

  const availableQuantityValue = availableQuantity ?? MAX_ALLOWED_QUANTITY_PER_CART
  return Math.min(
    getLimitPerOrder(product),
    availableQuantityValue,
    inStockQuantity ?? MAX_ALLOWED_QUANTITY_PER_CART
  )
}

export const getMaxLimitForMode = (isPackageMode: boolean, product: Product): number => {
  const maxLimit = getProductMaxOrderLimit(product)

  if (isPackageMode) {
    const packageNumberOfItems = product.packageNumberOfItems || 1
    const maxLimitPackage = Math.floor(maxLimit / packageNumberOfItems)

    return maxLimitPackage
  }

  return maxLimit
}

export const getCartQuantityLimitReached = (
  isPackageMode: boolean,
  product: Product,
  quantity: number
): number => {
  const maxLimit = getProductMaxOrderLimit(product)

  if (isPackageMode) {
    const packageNumberOfItems = product.packageNumberOfItems || 1
    const maxLimitPackage = Math.floor(maxLimit / packageNumberOfItems)
    return quantity > maxLimitPackage ? maxLimitPackage : 0
  }

  return quantity > maxLimit ? maxLimit : 0
}
