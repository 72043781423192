import { Header, Message, Body } from '../../molecules/invoice/SharedStyle'

import { Customer as customerDelivery } from '../../molecules/invoice/delivery/types'
import { Customer as customerPurchase } from '../../molecules/invoice/purchase/types'
import { OrderItem } from '../../molecules/invoice/purchase/types'
import { ProductBucket as ProductBucketsInterface } from '../../molecules/invoice/delivery/types'
import HeaderInformation from '../../molecules/invoice/HeaderInformation'
import SectionDetails from './SectionDetails'

import HeaderCommunication from '../../molecules/invoice/HeaderCommunication'
import InvoiceBottom from '../../molecules/invoice/InvoiceBottom'
import { TWO_DAYS_STORES } from '../../../common/constants'
interface Props {
  productBuckets: (OrderItem | ProductBucketsInterface)[]
  customer: customerDelivery | customerPurchase
  orderIds: number[]
  creationDatetime: Date
  payment: string
  isDelivery: boolean
  planId: string
  deliveryFee: number
}

const InvoiceDetails = ({
  isDelivery,
  orderIds,
  creationDatetime,
  payment,
  planId,
  customer,
  productBuckets,
  deliveryFee,
}: Props) => {
  const outsourcedProducts: (OrderItem | ProductBucketsInterface)[] = []
  const clubbiProducts: (OrderItem | ProductBucketsInterface)[] = []
  productBuckets.forEach((product) => {
    if (TWO_DAYS_STORES.includes(product.storeId)) {
      outsourcedProducts.push(product)
    } else {
      clubbiProducts.push(product)
    }
  })

  return (
    <Body>
      <Header>
        <Message>{!isDelivery ? <h3>Pedido</h3> : <h3>Fatura</h3>}</Message>
        <img src={'/img/new_clubbi.png'} alt={'Clubbi'} />
      </Header>

      <HeaderInformation
        customer={customer}
        orderIds={orderIds}
        creationDatetime={creationDatetime}
        payment={payment}
        planId={planId}
      />
      <HeaderCommunication isDelivery={isDelivery} />

      <SectionDetails
        productBuckets={clubbiProducts}
        isDelivery={isDelivery}
        isOutsourced={false}
      />
      {outsourcedProducts.length > 0 && (
        <SectionDetails productBuckets={outsourcedProducts} isDelivery={isDelivery} isOutsourced />
      )}

      <InvoiceBottom
        deliveryFee={deliveryFee}
        isDelivery={isDelivery}
        customer={customer}
        productBuckets={productBuckets as OrderItem[]}
      />
    </Body>
  )
}

export default InvoiceDetails
