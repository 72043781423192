import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { CopyIcon, Tooltip, themeClubbi, useMediaQuery } from 'clubbi-ui'

import { AvailableQuantity } from './AvailableQuantity'
import { useAppSelector } from '../../../common/hooks'
import { Product } from '../../../store/data/types'
import { salesFlyerActions } from '../../../store/slices/salesFlyer'

import {
  StyledCheckbox,
  StyledContainer,
  StyledContent,
  StyledCopyButton,
  StyledEan,
  StyledEanContainer,
} from './styles/EanSelect.style'

export const EanSelect = ({ product }: { product: Product }) => {
  const { ean } = product
  const dispatch = useDispatch()
  const { eans } = useAppSelector((state) => state.salesFlyer)
  const [copy, setCopy] = useState<boolean>(false)

  const isChecked = !!eans.length && (eans as string[]).includes(ean)

  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))
  const fontSize = isDesktop
    ? themeClubbi.typography.fontSizeXXS
    : themeClubbi.typography.fontSize3XS

  const copyEan = async () => {
    navigator.clipboard.writeText(ean)
    setCopy(true)

    setTimeout(() => {
      setCopy(false)
    }, 800)
  }

  const setEanInSalesFlyer = () =>
    dispatch(salesFlyerActions.setEanAndProductsFromSite({ ean, product, isChecked }))

  return (
    <StyledContainer>
      <StyledContent>
        <StyledEanContainer onClick={setEanInSalesFlyer} checked={isChecked}>
          <StyledCheckbox data-testid="ean-checkbox" type="checkbox" checked={isChecked} />
          <StyledEan data-testid="card-ean-info">{ean}</StyledEan>
        </StyledEanContainer>
        <Tooltip title="EAN copiado!" placement="bottom-end" open={copy}>
          <StyledCopyButton data-testid="card-ean-copy-button" onClick={copyEan}>
            <CopyIcon color={themeClubbi.palette.brandPurple[60]} size={fontSize} />
          </StyledCopyButton>
        </Tooltip>
      </StyledContent>
      <AvailableQuantity product={product} />
    </StyledContainer>
  )
}
