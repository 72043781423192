import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ButtonClubbi, ChevronDownIcon, CopyIcon, Tooltip, themeClubbi } from 'clubbi-ui'

import { useAppSelector } from '../../../common/hooks'
import { ProductInput, salesFlyerActions } from '../../../store/slices/salesFlyer'

import {
  StyledOpenButton,
  StyledDescription,
  StyledHeaderContainer,
  StyledTitle,
  StyledContainer,
  StyledInputsContainer,
  StyledInputLabel,
  StyledInputMerchatCode,
  StyledInputEans,
  StyledInfo,
  StyledCopyButton,
  StyledInfoAndButtonContainer,
  StyledDateContainer,
  StyledCheckbox,
} from './styles/DetailsFlyerInput.style'

interface DetailsFlyerInputProps {
  getFlyerProducts: () => void
  disabled: boolean
  products: ProductInput[]
}

export const DetailsFlyerInput = ({
  getFlyerProducts,
  disabled,
  products,
}: DetailsFlyerInputProps) => {
  const dispatch = useDispatch()
  const { merchantCodeInput, eans, includeDate } = useAppSelector((state) => state.salesFlyer)
  const [productsText, setProductsText] = useState('')
  const [open, setOpen] = useState<boolean>(true)
  const [copy, setCopy] = useState<boolean>(false)

  const setContainerStatus = () => {
    setOpen(!open)
  }

  const changeMerchanteCodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const codeInput = event.target.value
    if (codeInput.length <= 5)
      dispatch(salesFlyerActions.setMerchantCodeInput(codeInput.toUpperCase()))
  }

  const copyEans = async () => {
    navigator.clipboard.writeText(eans.join('\n'))
    setCopy(true)

    setTimeout(() => {
      setCopy(false)
    }, 800)
  }

  const setIncludeDate = () => {
    dispatch(salesFlyerActions.setIncludeDate(!includeDate))
  }

  useEffect(() => {
    if (products.length > 0) {
      const formattedText = products
        .map((p: ProductInput) => `${p.ean};${p.description};${p.price}`)
        .join('\n')
      setProductsText(formattedText)
    }
  }, [products])

  const handleProductsTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value
    setProductsText(newText)

    const updates = newText.split('\n').reduce(
      (acc, line) => {
        const [ean, ...rest] = line.split(';')
        if (ean) {
          const price = rest[1] || ''
          acc.eans.push(ean)
          if (price) acc.prices[ean] = price.replace(/[^\d,]/g, '').replace(',', '.')
        }
        return acc
      },
      { eans: [] as string[], prices: {} as Record<string, string> }
    )

    dispatch(salesFlyerActions.setEans(updates.eans))
    dispatch(salesFlyerActions.setEditedPrices(updates.prices))
  }

  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledOpenButton onClick={setContainerStatus}>
          <StyledDescription>
            <StyledTitle>1. Detalhes</StyledTitle>(Código e Lista de produtos)
          </StyledDescription>
          <ChevronDownIcon
            color={themeClubbi.palette.brandBlack.main}
            rotate={open}
            bold={true}
            fontSize={25}
          />
        </StyledOpenButton>
      </StyledHeaderContainer>
      {open && (
        <StyledInputsContainer>
          <StyledInputLabel>
            Código do cliente
            <StyledInputMerchatCode
              value={merchantCodeInput}
              onChange={changeMerchanteCodeInput}
              placeholder="QTKAU"
              type="text"
            />
          </StyledInputLabel>
          <StyledInputLabel>
            Lista de produtos
            <StyledInfoAndButtonContainer>
              <StyledInfo>
                Digite um EAN por linha Ex: 7891234567890 Ou formato: EAN;Descrição;Preço
              </StyledInfo>
              <Tooltip title="Lista de EANs copiada!" placement="bottom-end" open={copy}>
                <StyledCopyButton onClick={copyEans}>
                  <CopyIcon
                    color={themeClubbi.palette.brandPurple[60]}
                    size={themeClubbi.typography.fontSizeM}
                  />
                </StyledCopyButton>
              </Tooltip>
            </StyledInfoAndButtonContainer>
            <StyledInputEans value={productsText} onChange={handleProductsTextChange} />
          </StyledInputLabel>
          <StyledDateContainer>
            <StyledCheckbox type="checkbox" checked={includeDate} onChange={setIncludeDate} />
            Incluir data no encarte
          </StyledDateContainer>
          <ButtonClubbi
            data-cy="profile-login-button"
            typeButton="filled"
            size="small"
            label="Gerar encarte"
            onClick={getFlyerProducts}
            disabled={disabled}
            data-testid="generator-button"
          />
        </StyledInputsContainer>
      )}
    </StyledContainer>
  )
}
