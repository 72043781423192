import React from 'react'

import { UnavailableMode } from './UnavailableMode'
import { GetMoreForLessInformation } from '../../../molecules/getMoreForLessInformations/GetMoreForLessInformations'
import { useAppSelector } from '../../../../common/hooks'
import { getPackagePrice } from '../../../../common/productUtils'
import { dispatch } from '../../../../store'
import { getMaxLimitForMode } from '../../../../store/data/cartFunctions'
import { Product } from '../../../../store/data/types'
import { cartActions } from '../../../../store/slices/cart'

import { StyledContainerPrices } from './styles/CardPriceInfo.style'
import PackagePriceInfo from './PackagePriceInfo'
import UnitPriceInfo from './UnitPriceInfo'

interface CardPriceInfoProps {
  mode?: string
  warning: boolean
  setWarning: any
  product: Product
  setLimitReached: any
  quantity: number
}

const CardPriceInfo = ({
  mode,
  warning,
  setWarning,
  product,
  setLimitReached,
  quantity,
}: CardPriceInfoProps) => {
  const cartItem = useAppSelector((state) => state.cart[product.id])

  const [firstSupplier] = product.supplierPrices
  const unitPrice = firstSupplier.price

  const { getMoreForLess } = firstSupplier

  const packagePriceSupplier = getPackagePrice(product)

  const handleModeChange = (isPackageMode: boolean) => {
    const quantity = cartItem?.quantity || 0
    const maxLimit = quantity > getMaxLimitForMode(!!cartItem?.isPackageMode, product)
    setLimitReached(maxLimit)

    dispatch(cartActions.setMode({ product, isPackageMode }))
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.id === 'package-selector') {
      handleModeChange(true)
    } else {
      handleModeChange(false)
    }

    setWarning(false)
  }

  const unvailableUnit = !unitPrice
  const unvailablePackage = !packagePriceSupplier

  return (
    <StyledContainerPrices>
      {getMoreForLess ? (
        <>
          {getMoreForLess.isPackageMode ? (
            <PackagePriceInfo
              mode={mode}
              product={product}
              handleClick={handleClick}
              warning={warning}
            />
          ) : (
            <UnitPriceInfo
              warning={warning}
              mode={mode}
              handleClick={handleClick}
              product={product}
            />
          )}
          <GetMoreForLessInformation product={product} quantity={quantity} />
        </>
      ) : (
        <>
          {unvailablePackage ? (
            <UnavailableMode
              unvailablePackage={unvailablePackage}
              unvailableUnit={unvailableUnit}
            />
          ) : (
            <PackagePriceInfo
              mode={mode}
              product={product}
              handleClick={handleClick}
              warning={warning}
            />
          )}
          {unvailableUnit ? (
            <UnavailableMode
              unvailableUnit={unvailableUnit}
              unvailablePackage={unvailablePackage}
            />
          ) : (
            <UnitPriceInfo
              warning={warning}
              mode={mode}
              handleClick={handleClick}
              product={product}
            />
          )}
        </>
      )}
    </StyledContainerPrices>
  )
}

export default CardPriceInfo
