import { CartCheckoutIcon, ChatIcon, PeopleIcon, StoreIcon, TruckIcon } from 'clubbi-ui'
import {
  InputsFormLabels,
  SideMenuDatum,
  TimeRestrictionProps,
  ValueLabelDatum,
} from './interfaces'
import { CX_WHATSAPP_LINK } from '../../../common/constants'
import { ContactType } from '../../../store/data/types'

export const deliveryDaysData: ValueLabelDatum[] = [
  { value: 2, label: 'Segunda-feira', mobileLabel: 'S' },
  { value: 3, label: 'Terça-feira', mobileLabel: 'T' },
  { value: 4, label: 'Quarta-feira', mobileLabel: 'Q' },
  { value: 5, label: 'Quinta-feira', mobileLabel: 'Q' },
  { value: 6, label: 'Sexta-feira', mobileLabel: 'S' },
  { value: 7, label: 'Sábado', mobileLabel: 'S' },
]

export const timeSelectionData: ValueLabelDatum[] = [
  { label: '07:00', value: '07:00:00' },
  { label: '07:30', value: '07:30:00' },
  { label: '08:00', value: '08:00:00' },
  { label: '08:30', value: '08:30:00' },
  { label: '09:00', value: '09:00:00' },
  { label: '09:30', value: '09:30:00' },
  { label: '10:00', value: '10:00:00' },
  { label: '10:30', value: '10:30:00' },
  { label: '11:00', value: '11:00:00' },
  { label: '11:30', value: '11:30:00' },
  { label: '12:00', value: '12:00:00' },
  { label: '12:30', value: '12:30:00' },
  { label: '13:00', value: '13:00:00' },
  { label: '13:30', value: '13:30:00' },
  { label: '14:00', value: '14:00:00' },
  { label: '14:30', value: '14:30:00' },
  { label: '15:00', value: '15:00:00' },
  { label: '15:30', value: '15:30:00' },
  { label: '16:00', value: '16:00:00' },
  { label: '16:30', value: '16:30:00' },
  { label: '17:00', value: '17:00:00' },
  { label: '17:30', value: '17:30:00' },
  { label: '18:00', value: '18:00:00' },
  { label: '18:30', value: '18:30:00' },
  { label: '19:00', value: '19:00:00' },
  { label: '19:30', value: '19:30:00' },
  { label: '20:00', value: '20:00:00' },
  { label: '20:30', value: '20:30:00' },
  { label: '21:00', value: '21:00:00' },
  { label: '21:30', value: '21:30:00' },
  { label: '22:00', value: '22:00:00' },
  { label: '22:30', value: '22:30:00' },
  { label: '23:00', value: '23:00:00' },
]

export const inputFormLabels: InputsFormLabels[] = [
  {
    company: { label: 'Nome da Empresa', readOnly: true, name: 'merchantName' },
    contact: { label: 'Nome do Responsável', readOnly: true, name: 'userName' },
  },
  {
    company: { label: 'Código do Cliente', readOnly: true, name: 'merchantCode' },
    contact: { label: 'Email Principal', readOnly: true, name: 'email' },
  },
  {
    company: { label: 'Endereço', readOnly: true, name: 'fullAddress' },
    contact: { label: 'Email Financeiro', readOnly: false, name: 'financeEmail' },
  },
]
export const timeRestrictions: TimeRestrictionProps[] = [
  {
    startKey: 'deliveryTimeStart',
    finishKey: 'deliveryTimeFinish',
    fromLabel: 'A partir das:',
    toLabel: 'até',
    title: 'Em dias de semana',
  },
  {
    startKey: 'saturdayClosure',
    finishKey: null,
    fromLabel: 'Até às:',
    title: 'Aos Sábados',
  },
  {
    startKey: 'deliveryBreakTimeStart',
    finishKey: 'deliveryBreakTimeFinish',
    fromLabel: 'Entre as',
    toLabel: 'e',
  },
]

export const DELIVERY_BREAK_TOGGLE_LABEL = 'Temos intervalo indisponível para receber'

export const DELIVERY_BREAK_TOGGLE_SUB_LABEL =
  'Intervalo durante o dia em que não podemos receber. (Ex: Almoço)'

export const sideMenuData: SideMenuDatum[] = [
  {
    data: [
      {
        label: 'Dados da Empresa',
        icon: StoreIcon,
        href: '/profile',
      },
      {
        label: 'Dados de Contatos',
        icon: PeopleIcon,
        href: '/profile/contacts',
      },
    ],
    title: 'Dados',
  },
  {
    data: [
      {
        label: 'Pedidos em Aberto',
        icon: CartCheckoutIcon,
        href: '/pedidos-em-aberto',
      },
      {
        label: 'Minhas Entregas',
        icon: TruckIcon,
        href: '/invoices',
      },
    ],
    title: 'Pedidos',
  },
  {
    data: [
      {
        label: 'Fale com a gente',
        icon: ChatIcon,
        href: CX_WHATSAPP_LINK,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    ],
    title: 'Contatos',
  },
]

export const PROFILE_CONTACT_SECTION_TITLE = 'Contatos'

export const PROFILE_COMPANY_SECTION_TITLE = 'Quando conseguimos receber mercadorias'

export const PROFILE_COMPANY_SECTION_SUBTITLE =
  'Dias e horários em que o estabelecimento pode receber mercadorias.'

export const getProfileTitle = (isContactPage: boolean) =>
  `Dados ${isContactPage ? 'de Contato' : 'da Empresa'}`

export const contactsData = [
  { label: 'WhatsApp Principal', tag: ContactType.MAIN_WHATSAPP, isOptional: false },
  { label: 'Financeiro', tag: ContactType.FINANCIAL, isOptional: false },
  { label: 'Recebimento', tag: ContactType.RECEIPT, isOptional: true },
  { label: 'Compra', tag: ContactType.PURCHASE, isOptional: true },
  { label: 'Outro', tag: ContactType.OTHERS, isOptional: true },
]

export const OPTIONAL_LABEL = '(Opcional)'

export const MAX_RESTRICTION_DAYS = 2

export const MIN_DELIVERY_DAYS = 6 - MAX_RESTRICTION_DAYS

export const MIN_DELIVERY_WINDOW_HOURS = 6

export const SATURDAY_VALUE = 7

export const DEFAULT_DELIVERY_TIME_START = '07:00:00'

export const DEFAULT_DELIVERY_TIME_FINISH = '20:00:00'

export const MIN_SATURDAY_CLOSURE = '13:00:00'

export const MIN_WEEKDAY_CLOSURE = '15:00:00'

export const MIN_PHONE_LENGTH = 10

export const MIN_PHONE_LENGTH_WITH_MASK = 14
