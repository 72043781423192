import React from 'react'

import { setCurrency, unitMode } from '../../../../common/cardUtils'
import { WITHOUT_PRICE } from '../../../../common/constants'
import { useAppSelector } from '../../../../common/hooks'
import { showPrices } from '../../../../common/utils'
import { Product } from '../../../../store/data/types'

import { StyledContainerDiscount, StyledCurrency } from './styles/CardPriceInfo.style'
import {
  StyledContainerPricesUnit,
  StyledContainerUnit,
  StyledContainerUnitPrice,
  StyledModeUnit,
  StyledPriceUnit,
} from './styles/UnitPriceInfo.style'

interface UnitPriceInfoProps {
  mode?: string
  warning: boolean
  product: Product
  handleClick: any
}

const UnitPriceInfo = ({ mode, warning, product, handleClick }: UnitPriceInfoProps) => {
  const { discount, price } = product
  const { merchantCode } = useAppSelector((state) => state.session)

  const isUnitSelected = mode === unitMode
  const isPromotion = !!discount

  const [firstSupplier] = product.supplierPrices
  const unitPrice = firstSupplier.getMoreForLess
    ? firstSupplier.getMoreForLess.priceWithoutPromotion
    : firstSupplier.price

  const finalUnitPrice = showPrices(merchantCode) ? setCurrency(unitPrice) : WITHOUT_PRICE

  return (
    <StyledContainerUnit
      onClick={handleClick}
      id="unit-selector"
      visible={unitPrice}
      isUnitSelected={isUnitSelected}
      warning={warning}
    >
      <StyledModeUnit isUnitSelected={isUnitSelected} warning={warning} visible={unitPrice}>
        Fracionado
      </StyledModeUnit>
      <StyledContainerPricesUnit>
        <StyledContainerUnitPrice isUnitSelected={isUnitSelected}>
          <StyledCurrency fontSizeMobile={'0.5rem'}>R$</StyledCurrency>
          <StyledPriceUnit isUnitSelected={isUnitSelected} data-testid="unit-price">
            {finalUnitPrice}
          </StyledPriceUnit>
          <span>/un.</span>
          {isPromotion && (
            <StyledContainerDiscount>
              <span>R${setCurrency(price)}</span>
            </StyledContainerDiscount>
          )}
        </StyledContainerUnitPrice>
      </StyledContainerPricesUnit>
    </StyledContainerUnit>
  )
}

export default UnitPriceInfo
