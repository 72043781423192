import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Product } from '../data/types'
import { getPrice } from '../../components/molecules/flyer/ProductCardFlyer'

type EditedPrices = Record<string, string>

export type ProductInput = {
  ean: string
  description: string
  price: string | number
}

type State = {
  merchantCodeInput: string | undefined
  eans: string[] | []
  products: ProductInput[]
  editedPrices: EditedPrices
  includeDate: boolean
  modeGenerator: boolean
}

const initialState: State = {
  merchantCodeInput: '',
  eans: [],
  products: [],
  editedPrices: {},
  includeDate: true,
  modeGenerator: false,
}

export const salesFlyerSlice = createSlice({
  name: 'salesFlyer',
  initialState: initialState,
  reducers: {
    setMerchantCodeInput: (state, action: PayloadAction<string>) => {
      const merchantCodeInput = action.payload
      return {
        ...state,
        merchantCodeInput,
      }
    },
    setEans: (state, action: PayloadAction<string[]>) => {
      const eans = action.payload
      return {
        ...state,
        eans,
      }
    },
    setIncludeDate: (state, action: PayloadAction<boolean>) => {
      const includeDate = action.payload
      return {
        ...state,
        includeDate,
      }
    },
    setEanAndProductsFromSite: (
      state,
      action: PayloadAction<{ ean: string; product: Product; isChecked: boolean }>
    ) => {
      const { ean, product, isChecked } = action.payload
      const eans = isChecked ? state.eans.filter((e) => e !== ean) : [...state.eans, ean]
      const products = isChecked
        ? state.products.filter((p) => p.ean !== ean)
        : [
            ...state.products,
            {
              ean: product.ean,
              description: product.description,
              price: getPrice(product),
            } as ProductInput,
          ]

      return {
        ...state,
        eans,
        products,
      }
    },
    setEditedPrices: (state, action: PayloadAction<EditedPrices>) => {
      return {
        ...state,
        editedPrices: action.payload,
      }
    },
    setModeGenerator: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        modeGenerator: action.payload,
      }
    },
    cleanUp: () => {
      return initialState
    },
  },
})

export const salesFlyerReducer = salesFlyerSlice.reducer
export const salesFlyerActions = salesFlyerSlice.actions
